<template>
  <h3>{{ title }}</h3>
</template>

<script>
export default {
    name:'subTitle',
    props:['title']
}
</script>

<style scoped>
    h3 {
        font-size: 30px;
        font-weight: 400;
        color: #333;
        line-height: 1em;
        height: 1em;
        text-align: center;
        padding: 20px 0;
        margin: 20px 0;
    }
</style>