<template>
  <div class="contact-us">
    <div class="contact-us-container">
      <div class="content">
        <p>{{ $t('contact.desc1') }}</p>
        <p>{{ $t('contact.desc2') }}</p>
      </div>
      <div class="map-wraper">
        <div class="location"></div>
        <div class="info">
          <h5>{{ $t('contact.title') }}</h5>
          <ul>
            <li>
              <div>
                <i class="icon"></i>
                <span class="name">{{ $t('contact.email') }}</span>
                <i class="split-line">/</i>
              </div>
              <span>{{ $t('contact.emailInfo') }}</span>
            </li>
            <li>
              <div>
                <i class="icon"></i>
                <span class="name">{{ $t('contact.address') }}</span>
                <i class="split-line">/</i>
              </div>
              <span>{{ $t('contact.addressInfo') }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUs',
  data() {
    return {
    }
  }
}
</script>

<style>
.contact-us {
  padding-top: 60px;
  padding-bottom: 60px;
}

.contact-us .contact-us-container {
  width: 1383px;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
}

.contact-us .contact-us-container > .content {
  flex: 5;
  margin-right: 60px;
  
}

.contact-us .contact-us-container > .content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 2.5;
  color: #666;
}

.contact-us .contact-us-container > .content p + p {
  margin-top: 24px;
}

.contact-us .contact-us-container > .map-wraper {
  flex: 5;
  display: flex;
  flex-direction: column;
}

.contact-us .contact-us-container > .map-wraper .location {
  width: 100%;
  height: 240px;
  background-color: #f5f5f5;
  background-image: url("~@/assets/images/contact-us-location.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-us .contact-us-container > .map-wraper .info h5 {
  font-size: 18px;
  font-weight: 700;
  color: #333;
  margin: 28px 0 22px;
}

.contact-us .contact-us-container > .map-wraper .info ul li {
  clear: both;
  color: #666;
  margin-top: 16px;
  display: flex;
}

.contact-us .contact-us-container > .map-wraper .info ul li > div {
  width: 116px;
  font-size: 15px;
  color: #999;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.contact-us .contact-us-container > .map-wraper .info ul li > span {
  flex: 1;
}

.contact-us .contact-us-container > .map-wraper .info ul li > div span {
  flex: 1;
}

.contact-us .contact-us-container > .map-wraper .info ul li .icon {
  display: inline-block;
  flex-basis: 18px;
  width: 18px;
  height: 18px;
  margin-right: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.contact-us .contact-us-container > .map-wraper .info ul li:nth-child(1) .icon {
  background-image: url("~@/assets/images/contact-us-icon01.png");
}

.contact-us .contact-us-container > .map-wraper .info ul li:nth-child(2) .icon {
  background-image: url("~@/assets/images/contact-us-icon03.png");
}

.contact-us .contact-us-container > .map-wraper .info ul li .split-line {
  font-size: 15px;
  margin: 0 2px;
  color: #ddd;
}

.contact-us .contact-us-container > .map-wraper .info ul li > span:nth-child(1) {
  flex-basis: 78px;
}

.contact-us .contact-us-container > .map-wraper .info ul li > span {
  font-size: 15px;
  color: #999;
}

</style>