<template>
  <div class="company-value">
    <div class="company-value-container">
        <Title title="eMaker Solutions"></Title>
        <ul>
            <li>
                <h5>{{ $t('value.vision') }}</h5>
                <p>{{ $t('value.visionDesc') }}</p>
            </li>
            <li>
                <h5>{{ $t('value.mission') }}</h5>
                <p>{{ $t('value.missionDesc') }}</p>
            </li>
            <li>
                <h5>{{ $t('value.values') }}</h5>
                <p>{{ $t('value.valuesDesc') }}</p>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
import Title from '@/components/SubTitle/index.vue'
export default {
    name:'OurVision',
    components: { Title },
}
</script>

<style scoped>
.company-value {
    background-color: rgb(245, 247, 249);
    padding-top: 20px;
    padding-bottom: 70px;
}

.company-value .company-value-container {
    margin: 0 auto;
    width: 1383px;
}

.company-value .company-value-container ul {
    display: flex;
    margin-top: 36px;
}

.company-value .company-value-container ul li {
    flex: 1;
}

.company-value .company-value-container ul li + li {
    margin-left: 180px;
}


.company-value .company-value-container ul li h5 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.company-value .company-value-container ul li p {
    font-size: 16px;
    font-weight: normal;
    color: #666;
    line-height: 2.5;
    margin-top: 30px;
}
</style>