
<template>
  <div clss="about">
    <div class="about-container">
        <OUrVision></OUrVision>
        <ContactUs></ContactUs>
    </div>
  </div>
</template>

<script>
import ContactUs from './ContactUs/index.vue'
import OUrVision from './OurVision/index.vue'
export default {
    name:'About',
    components:{ ContactUs, OUrVision },
    data(){
        return {}
    },
    methods: {
    }
}
</script>

<style scoped>
    .about-container {
        min-height: calc(100vh - 308px);
    }
</style>